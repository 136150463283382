<template>
    <ul class="list-group">
        <li class="list-group-item pointer" v-for="game in games" :key="game.id" @click="addGame(game)">
            {{ game.name }}
        </li>
    </ul>
</template>

<script>
    import { mapGetters } from "vuex";
    import { mapMutations } from "vuex";
    export default {
        props: ["id", "userId"],
        data: function() {
            return {
                placeId: this.id,
                user_id: this.userId
            }
        },
        created() {
            //
        },
        mounted() {
            this.getGames();
            this.getGamesByPlace();
            this.updateData();
        },
        computed: {
            ...mapGetters(["games", "myGames"]),
        },
        methods: {
            ...mapMutations(["setGames", "setMyGames", "setGame", "setPlaceId", "setUser", "setPlace"]),
            async getGames(){
                let response = await axios.get(`/admin/api/games`);
                this.setGames(response.data);
            },
            async addGame(game){
                this.setGame(game);
                this.$root.$bvModal.show("modal-add-game");
            },
            async getGamesByPlace(id) {
                let res = await axios.get(`/admin/api/place/${this.placeId}/games`);
                this.setMyGames(res.data);
            },
            async updateData(){
                let res = await axios.post(`/admin/api/me`, { user_id: this.user_id });
                let place = await axios.post(`/places/api/me`, { user_id: this.placeId });
                this.setPlaceId(this.placeId);
                this.setPlace(place.data);
                this.setUser(res.data);
            }
        }
    }
</script>
