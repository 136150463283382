<template>
    <div class="row">
    <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
               
                <table class="table">
                    <thead>
                        <tr>
                            <th>Sorteo</th>
                            <th>Ticket #</th>
                            <th>Fecha</th>
                            <th>Hora</th>
                            <th class="text-center">Total</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                            <tr data-toggle="collapse" :data-target="key" v-for="(ticket, index) in allTickets" :key="index" class="accordion-toggle pointer">
                             
                                <td>{{ ticket.number }}</td>
                                <td>{{ ticket.game_date|formatDate }}</td>
                                <td>{{ ticket.game_time|formatTime }}</td>
                                <td class="text-center">₡ {{ ticket.total|toCurrency }}</td>
                                <td>
                                    <a href="#" title="Sorteos">
                                        <i class="icon-lg text-muted mr-1 pb-3px" data-feather="award"></i>
                                    </a>
                                    <a href="#" class="action-trash" title="Eliminar">
                                      <i class="icon-lg text-muted pb-3px" data-feather="trash-2"></i>
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="12" class="hiddenRow">
                                    <div class="accordian-body collapse" :id="key"> 
                                        <div class="inner_table">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th>Número</th>
                                                        <th>Monto</th>
                                                       
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(list, i) in ticket.list" :key="i">
                                                        <td>{{ list.number }}</td>
                                                        <td>₡ {{ list.amount|toCurrency }}</td>
                                                       
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>    
                                    </div>
                                </td>
                            </tr>
                    </tbody>
                </table>
                <div class="grand_total">
                    <strong>
                        Total: ₡ 10
                    </strong>
                </div>
            </div>
          </div><!--/card-body-->
        </div><!--/card-->
    </div><!--/col-12-->
</div><!--/row-->
</template>
<script>
    import { required, minLength, between } from 'vuelidate/lib/validators'
    export default {
        props: ["tickets"],
        data: function() {
            return {
                allTickets: this.tickets,
            }
        },
        filters: {
            toCurrency (value) {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
        },
        created() {
            //
        },
        async mounted() {
            
        },
        computed: {

        },
        methods: {

        }
    }
</script>
