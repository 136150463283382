<template>
    <div>
        <div class="row">
            <div class="col-md-8 grid-margin">
                <div class="d-flex justify-content-between align-items-center flex-wrap grid-margin">
                    <div><h4 class="mb-3 mb-md-0">Números ganadores de hoy</h4></div>
                    <form class="d-flex align-items-center flex-wrap text-nowrap">  
                        <div class="input-group date dashboard-date mr-2 mb-2 mb-md-0 d-md-none d-xl-flex" id="dashboardDate">
                            <DatePicker id="date_filter" label="Seleccionar" locale="es" only-date auto-close formatted="DD-MM-YYYY" no-button v-model="date_filter"/>
                        </div>
                        <button class="btn btn-primary btn-app btn-icon-text mb-2 mb-md-0" @click="searchWinners">
                            <i class="btn-icon-prepend" data-feather="filter"></i>
                            Filtrar por fecha
                        </button>
                    </form>
                </div>
                <div class="result_list" v-if="!loading">
                    <div class="result_list_item" v-for="(result, index) in results" :key="index">
                        <div class="result_list_item_header" v-if="result.game">
                            <strong>{{ result.game.name }} {{ result.time|formatTime }}</strong>
                        </div>
                        <div class="result_list_items_bg">
                            <strong>{{ result.number }}</strong>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <h6 class="card-title">Crear número ganador</h6>
                        <form @submit="createWinner">
                            <p v-if="errors.length">
                                <b>Por favor, corrija el(los) siguiente(s) error(es):</b>
                                <ul>
                                    <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
                                </ul>
                            </p>
                            <div class="form-group">
                                <select ref="game" class="form-control" @change="handleGame($event)">
                                    <option value="">Seleccionar Sorteo</option>
                                    <option v-for="(game, index) in games" :key="index" :value="game.id">{{ game.name }}</option>
                                </select>
                            </div>
                            <div class="form-group" v-if="show">
                                <select ref="schedule" class="form-control" v-model="time">
                                    <option value="">Seleccionar Horario</option>
                                    <option v-for="(schedule, index) in schedules" :key="index" :value="schedule.time">{{ schedule.time }}</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <DatePicker id="date" label="Seleccionar Fecha" locale="es" only-date auto-close formatted="DD-MM-YYYY" v-model="date"/>
                            </div>
                            <div class="form-group">
                                <input type="text" class="form-control" v-model="number" placeholder="Número Ganador">
                            </div>
                            <div class="form-group" v-if="is_busted">
                                <select class="form-control" @change="handleBusted($event)">
                                    <option value="">Paga reventado</option>
                                    <option value="yes">Sí</option>
                                    <option value="not">No</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <button type="submit" class="btn btn-primary">Crear Número</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="duende_resultados">
            <img :src="image" alt="Pegatiempos" class="tiny_icon">
        </div>
    </div>
</template>

<script>
    import { required, minLength, between } from 'vuelidate/lib/validators'
    export default {
        props: ["userId", "image"],
        data: function() {
            return {
                errors: [],
                placeId: this.userId,
                games: [],
                schedules: [],
                show: false,
                game_id: null,
                time: null,
                number: null,
                date: null,
                date_filter: null,
                results: [],
                is_busted: false,
                pay_busted: false,
                loading: false
            }
        },
        filters: {
            toCurrency (value) {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
        },
        created() {
            //
        },
        async mounted() {
            await this.getResults();
            await this.getGamesByPlace();
            await this.watchResults();
        },
        computed: {
        },
        methods: {
            async watchResults(){
                console.log('--- running ----');
                let pusher = new Pusher('5148d6ef5727a6f02ea6', {
                    cluster: 'us2'
                });

                let channel = pusher.subscribe('balances-channel');
               
                channel.bind('update-balance', function(data) {
                    console.log('data:', data);
                });

            },
            async getGamesByPlace(id) {
                let res = await axios.get(`/admin/api/games`);
                this.games = res.data;
            },
            async getResults() {
                let loader = this.$loading.show({ canCancel: false });
                let res = await axios.post('/admin/api/results', {});
                this.results = res.data;
                loader.hide();
            },
            async refreshResults() {
                let res = await axios.post('/admin/api/results', {});
                this.results = res.data;
            },
            handleGame: async function(e){
                let gameId = e.target.value;
                let currentGame = this.games.find((item) => item.id == gameId);
                this.game_id = gameId;
                this.is_busted = currentGame.is_busted;
                let res = await axios.post(`/admin/api/games/${gameId}/schedules`, { date: ''});
                this.show = true;
                this.schedules = res.data;
            },
            handleBusted: async function(e){
                if(e.target.value == "yes"){
                    this.pay_busted = true;
                }else {
                    this.pay_busted = false;
                }
            },
            async searchWinners(e){
                e.preventDefault();

                let res = await axios.post('/admin/api/results', { date: this.date_filter });
                this.results = res.data;
            },

            createWinner: async function(e){
                e.preventDefault();
    
                if (this.game_id && this.time && this.number && this.date) {
                    
                    let data = {
                        game_id: parseInt(this.game_id),
                        time: this.time,
                        date: this.date,
                        number: this.number,
                        placeId: this.placeId,
                        pay_busted: this.pay_busted
                    }

                    let loader = this.$loading.show({ canCancel: false });

                    try {
                        await axios.post(`/admin/api/createResult`, data);
                        this.refreshResults();
                        loader.hide();
                        window.location.reload();
                            
                    } catch (error) {
                        loader.hide();
                        Vue.notify({
                            type: 'error',
                            title: 'Oops!',
                            text: 'Este sorteo ya tiene un número ganador'
                        });
                    }
                }

                this.errors = [];

                if (!this.game_id) {
                    this.errors.push('El sorteo es obligatorio.');
                }
                if (!this.time) {
                    this.errors.push('El horario es obligatorio.');
                }

                if (!this.date) {
                    this.errors.push('La fecha es obligatoria.');
                }

                if (!this.number) {
                    this.errors.push('El número ganador es obligatorio.');
                }

                e.preventDefault();              
            }
        }
    }
</script>
