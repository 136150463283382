/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue');

import Notifications from 'vue-notification';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import BootstrapVue from 'bootstrap-vue';
import Vuelidate from 'vuelidate'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import VueHtmlToPaper from 'vue-html-to-paper';
import moment from 'moment'
import VueHotkey from 'v-hotkey'

Vue.use(BootstrapVue);
Vue.use(Notifications);
Vue.use(Loading);
Vue.use(Vuelidate)

Vue.use(VueHotkey, {
  "8": 56,
  "*": 106
});

const options = {
  styles: [
    'https://ganatiempos.com/css/print.css' // <- inject here
  ]
}

Vue.use(VueHtmlToPaper, options);

import store from "./stores/index";
import Vue from 'vue';

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('example-component', require('./components/ExampleComponent.vue').default);
Vue.component('balance-place', require('./components/PlaceBalance.vue').default);
Vue.component('playing-component', require('./components/PlayingComponent.vue').default);

Vue.component('add-games', require('./views/AddGames.vue').default);
Vue.component('my-games', require('./views/MyGames.vue').default);
Vue.component('playing-view', require('./views/PlayingView.vue').default);
Vue.component('result-view', require('./views/ResultView.vue').default);
Vue.component('report-view', require('./views/ReportView.vue').default);
Vue.component('report-admin', require('./views/ReportAdmin.vue').default);
Vue.component('report-place', require('./views/ReportPlace.vue').default);
Vue.component('ticket-list', require('./views/TicketList.vue').default);
Vue.component('ticket-view', require('./views/TicketView.vue').default);
Vue.component('winner-view', require('./views/WinnerView.vue').default);
Vue.component('clear-balance', require('./views/ClearBalance.vue').default);
Vue.component('consult-list', require('./views/ConsultList.vue').default);

Vue.component('modal-add-game', require('./components/modals/ModalAddGame.vue').default);
Vue.component('modal-update-game', require('./components/modals/ModalUpdateGame.vue').default);

Vue.component('DatePicker', VueCtkDateTimePicker);

Vue.filter('formatDate', function(value) {
    if (value) {
      return moment(String(value)).format('DD/MM/YYYY')
    }
});

Vue.filter('formatDates', function(value) {
  if (value) {
    return moment(value).format('DD/MM/YYYY')
  }
});

Vue.filter('formatTime', function(value) {
    if (value) {
      return moment(value, 'HH:mm:ss').format('hh:mm A')
    }
});

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
    store
});
