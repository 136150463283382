<template>
    <div class="my_games">
        <div class="my_game_item pointer" v-for="game in myGames" :key="game.id" @click="updateGame(game)">
            <button @click="deleteGame(game)">X</button>
            <strong>Paga {{ game.payment_game }} veces</strong>
            <span>{{ game.game.name }}</span>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import { mapMutations } from "vuex";
    export default {
        props: ["id"],
        data: function() {
            return {
                placeId: this.id
            }
        },
        created() {
            //
        },
        mounted() {
            this.getGamesByPlace();
        },
        computed: {
            ...mapGetters(["myGames"]),
        },
        methods: {
            ...mapMutations(["setMyGames", "setGame"]),
            async getGamesByPlace(id) {
                let res = await axios.get(`/admin/api/place/${this.placeId}/games`);
                this.setMyGames(res.data);
            },
            async updateGame(game){
                this.setGame(game);
                this.$root.$bvModal.show("modal-update-game");
            },
            async deleteGame(game){
                try {
                    let res = await axios.delete(`/admin/api/delete/game/${game.id}`);
                    this.getGamesByPlace(this.placeId);
                } catch (error) {
                    
                }
            }
        }
    }
</script>
