<template>
    <div class="row">
        <div class="col-12 col-lg-6">
            <div class="playing_content" v-if="dateMatch">
                <h4>Por favor actualizar su fecha.</h4>
                <p>La fecha en su dispositivo debe estar actualizada según la red.</p>
            </div>
            <div class="playing_content" v-if="dateMatch === false">
                <div class="error_list" v-if="showError">
                    <strong>Por favor corregir los siguientes errores</strong>
                    <ul>
                        <li v-for="(number, index) in errorList" :key="index">
                            {{
                                number.available_to_sell === 0 ? `El numero ${number.number} ya no tiene disponble para venta` :
                                `El número ${number.number} tiene un disponbile para venta de ₡ ${number.available_to_sell|toCurrency}`
                            }}
                        </li>
                    </ul>
                </div>

                <div class="content_steps">
                    <div class="step">
                        <div class="step_info" :class="[hide_game ? 'selected' : '']">  
                            <strong v-if="!hide_game">Seleccionar Sorteo</strong>
                            <span v-if="hide_game">{{ current_game }}</span>
                            <span v-if="hide_game" class="edit_icon" @click="toggleGame">
                                <img :src="picture" alt="Edit Icon" class="tiny_icon">
                            </span>
                        </div>
                        <div class="my_games" v-if="!hide_game">
                            <div 
                            :class="[
                                isSelected(key) ? 'my_game_item pointer selected' : 'my_game_item pointer',
                            ]"
                            v-for="(game, key) in myGames" :key="game.id" @click="selectModule(key)">
                                <strong>{{ game.game.name }}</strong>
                                <span v-if="!game.game.is_busted">(PAGA {{ game.payment_game }} VECES)</span>
                                <span v-if="game.game.is_busted">(PAGA {{ game.payment_game }}, {{ game.payment_busted }} VECES)</span>
                            </div>
                        </div><!--/my_games-->
                    </div>

                    <div class="step" v-if="showDate">
                        <div class="step_info" :class="[hide_date ? 'selected' : '']">  
                            <strong v-if="!hide_date">Seleccionar Fecha</strong>
                            <span v-if="hide_date">{{ date }}</span>
                            <span v-if="hide_date" class="edit_icon" @click="toggleDate">
                                <img :src="picture" alt="Edit Icon" class="tiny_icon">
                            </span>
                        </div>
                        <div class="step_date" v-if="!hide_date">
                            <DatePicker 
                                id="date" 
                                label="Seleccionar Fecha" 
                                locale="es" 
                                only-date 
                                auto-close
                                format="DD/MM/YYYY"
                                formatted="DD/MM/YYYY" 
                                v-model="date"
                                :min-date="min_server_date"
                            />
                        </div>
                    </div>

                    <div class="step" v-if="showSchedule">
                        <div class="step_info" :class="[hide_time ? 'selected' : '']">  
                            <strong v-if="!hide_time">Seleccionar Fecha y Hora</strong>
                            <span v-if="hide_time">{{ current_time|formatTime }}</span>
                            <span v-if="hide_time" class="edit_icon" @click="toggleTime">
                                <img :src="picture" alt="Edit Icon" class="tiny_icon">
                            </span>
                        </div>
                        <div class="my_times" v-if="!hide_time">
                            <div 
                                :class="[
                                    isTimeSelected(index) ? 'my_time_item pointer selected' : 'my_time_item pointer'
                                ]" 
                                v-for="(schedule, index) in schedules" :key="schedule.id" 
                                @click="selectTime(index)">
                                <span>{{ schedule.time|formatTime }}</span>
                            </div>
                        </div><!--/my_times-->
                    </div>

                    <div class="step">
                        <div class="step_info">  
                            <strong>Juega y Gana</strong>
                            <button
                                v-if="current_game !== '' && current_time !== '' && copyNumbers" 
                                @click="handleCopy" 
                                class="btn btn-app btn-primary btn-copy">Pegar Jugada</button>
                        </div>
                        <div class="number_listing" v-if="numbers.length > 0" >
                            <div class="number_playing" v-for="(number, index) in numbers" :key="index">
                                <button v-if="!showTicket" @click="deleteNumber(index)">X</button>
                                <div class="circle">{{ number.number }}</div>
                                <span>₡ {{ number.amount|toCurrency }}</span>
                                <span v-if="is_busted">₡ {{ number.amount_busted|toCurrency }}</span>
                            </div>
                        </div>
                    
                        <div class="numbers_input" v-if="!showTicket">
                            <input 
                                type="number"
                                :ref="'number'" 
                                :class="{'is_invalid': error_number }"
                                v-model.trim="number"
                                placeholder="Número"
                                @input="handleNumber($event)">
                            
                            <input 
                                type="number"
                                :ref="'amount'" 
                                :class="{'is_invalid': error_amount }"
                                v-model.trim="amount"
                                :placeholder="[[ mask || 'Monto' ]]"
                                v-on:keyup.enter="addNumber($event)">

                            <input
                                v-if="is_busted"
                                type="number"
                                :ref="'amount_reventado'" 
                                :class="{'is_invalid': error_reventado }"
                                v-model.trim="amount_reventado"
                                placeholder="Monto"
                                v-on:keyup.enter="addNumber($event)">

                            <button class="btn_manual_add" @click="addManual">
                                +
                            </button>
                        </div>

                        <div class="total_sell" v-if="!showTicket">
                            Venta total: ₡ {{ total | toCurrency }}
                        </div>

                        <button v-if="!showTicket" class="btn btn-block btn-app btn-primary mt-3" @click="createTicket" :disabled='can_sell'>VENDER TICKET</button>
                        
                        <button v-if="showTicket" class="btn btn-block btn-app btn-primary mt-4" @click="clearAll">Seguir Jugando</button>
                        <button v-if="showTicket" class="btn btn-block btn-app btn-primary mt-4" @click="print">Imprimir Ticket</button>
                    </div>
                </div>


                <div id="printMe">
                    <div class="print_logo">
                        <img :src="logo" alt="GanaTiempos">
                    </div>
                    <div class="print_detail">
                        <strong>{{ name }}</strong>
                    </div>
                    <div class="print_buy">
                        <span>{{ timestamp }}</span>
                        <span>{{ current_game }}</span>
                        <span>{{ date }} - {{ time|formatTime }}</span>
                    </div>
                    <div class="print_head">
                        <strong>Nº</strong>
                        <strong>APUESTA</strong>
                    </div>
                    <div class="print_list">

                        <div class="print_list_item" v-if="!is_busted" v-for="(number, index) in groups" :key="index">
                            <div>
                                {{ joinNumbers(number) }}
                            </div>
                            <span>{{ index }}</span>
                        </div>

                        <div class="print_list_item" v-if="is_busted" v-for="(number, index) in sellings" :key="index">
                            <div>{{ index }}</div>
                            <div>
                                <span>{{ joinAmount(number)|toCurrency }}</span> -
                                <span>{{ sumBusted(number)|toCurrency }}</span>
                            </div>
                        </div>

                    </div>
                    <div class="print_head">
                        <strong>TOTAL</strong>
                        <strong class="print_head_total">{{ total| toCurrency }}</strong>
                    </div>
                    <div class="print_thanks">
                        <span>¡Gracias por jugar!</span>
                        <span>GanaTiempos</span>
                    </div>
                    <div class="print_number" v-if="ticketCreated">
                        <span>Ticket: {{ ticketCreated.number }}</span>
                    </div>
                </div>
                
            </div>
        </div>
        <span v-hotkey="keymap"></span>
        <div class="col-12 col-lg-6" v-if="showList">
            <div class="number_list">
                <div :class="[list.restriction ? 'number_list_item' : 'number_list_item']" v-for="(list, index) in lists" :key="index">
                    <span>{{ list.number }}</span>
                    <span>₡{{ list.bet }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import { mapMutations } from "vuex";
    import { validationMixin } from "vuelidate"
    import moment from 'moment';
    import _ from 'lodash';

    export default {
        mixins: [validationMixin],
        props: ["id", "picture", "logo", "name"],
        data: function() {
            return {
                placeId: this.id,
                selected: null,
                time_selected: null,
                schedules: [],
                game: null,
                time: null,
                date: null,
                show: false,
                showDate: false,
                showSchedule: false,
                showTicket: false,
                hide_game: false,
                hide_date: false,
                current_game: '',
                hide_time: false,
                current_time: '',
                showList: false,
                numbers: [],
                number: '',
                amount: '',
                amount_reventado: '',
                ticketCreated: null,
                lists: [],
                mask: '',
                prints: [],
                numbers_restrictions: null,
                amount_restriction: null,
                error_max: false,
                is_busted: false,
                is_valid: false,
                error_number: false,
                error_amount: false,
                error_reventado: false,
                copyNumbers: false,
                can_sell: true,
                min_date: new Date(),
                min_server_date: null,
                dateMatch: false,
                showError: false,
                errorList: [],
            }
        },
        filters: {
            toCurrency (value) {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
        },
        created() {
            //
        },
        mounted() {
            this.getTime();
            this.getGamesByPlace();
            this.checkPreviousTicket();
        },
        watch: {
            date: async function (val) {
                this.hide_date = true;
                this.showSchedule = true;
                this.date = val;

                let res = await axios.post(`/admin/api/games/${this.game.game_id}/schedules`, {
                    date: val
                });

                this.schedules = res.data;

                if(res.data.length === 1){
                    this.$nextTick(() => {
                    this.selectTime(0);
                    }); 
                }
            },
            amount_reventado: function(val){
                if(parseInt(val) > parseInt(this.amount)){
                    Vue.notify({
                        type: 'error',
                        title: 'Oops!',
                        text: 'El monto de reventado no puede ser mayor al monto'
                    });
                    this.amount_reventado = '';
                    return false;
                }
            },
            numbers: function(values){
                if(values.length > 0){
                    this.can_sell = false;
                }else {
                    this.can_sell = false;
                }
            },
            allSchedules: function(values){
                console.log('all values: ', values);
            }
        },
        computed: {
            ...mapGetters(["myGames"]),
            keymap() {
                return {
                    "*": this.confirm,
                };
            },
            total: function() {
                if (!this.numbers) {
                    return 0;
                }

                if(this.is_busted){
                    return this.numbers.reduce(function (total, value) {
                        return total + Number(value.amount) + Number(value.amount_busted);
                    }, 0);
                }else{
                    return this.numbers.reduce(function (total, value) {
                        return total + Number(value.amount);
                    }, 0);
                }
            },
            timestamp: function () {
                return moment().format('DD/MM/YYYY hh:mm a')
            },
            sellings() {
                return _.groupBy(this.numbers, 'number') 
            },
            groups() {
                return _.groupBy(this.numbers, 'amount') 
            },
            minEndDate() { 
                return moment().format() 
            }
        },
        methods: {
            ...mapMutations(["setMyGames"]),

            async checkPreviousTicket(){
                let currentTicket = localStorage.getItem('ticket');
                if(currentTicket !== null) {
                    this.copyNumbers = true;
                }
            },

            async handleCopy(){
                let currentTicket = localStorage.getItem('ticket');
                if(currentTicket !== null) {
                    let ticket = JSON.parse(currentTicket);
                    this.numbers = ticket;
                    this.copyNumbers = false;
                }
            },
            async getTime() {
                let res = await axios.get(`/places/api/getTime`);
                this.min_server_date = moment(res.data.date, 'DD/MM/YYYY').format('YYYY-MM-DD');
                this.date = res.data.date;
                let localDate = moment().format('YYYY-MM-DD');

                if(localDate !== moment(res.data.date, 'DD/MM/YYYY').format('YYYY-MM-DD')){
                    this.dateMatch = true;
                }else {
                    console.log('match');
                    this.dateMatch = false;
                }
            },
            async getGamesByPlace(id) {
                let res = await axios.get(`/admin/api/place/${this.placeId}/games`);
                this.setMyGames(res.data);
            },
            validateState(item, index) {
                const { $dirty, $error, $model } = item
                if($dirty) {
                    return !$error;
                }else {
                    if($model.length == 2){
                        return this.$nextTick(() => {
                            let input = this.$refs.amount[index];
                            input.focus();
                        });
                    }
                }
            },
            addManual: function(){
                let current = this.amount;
                
                if(current == ''){

                    if(this.numbers.length > 0){
                        const lastItem = this.numbers[this.numbers.length - 1];
                        let lastIndex = this.numbers.length - 1;
                        this.mask = lastItem.amount;

                        //validate restriction
                        if(!this.game.game.is_monkey){
                            this.numbers_restrictions.map((item, i) => {
                                if(item == this.number){
                                    if(parseInt(this.amount) > this.amount_restriction){
                                        this.error_max = true;
                                    }else{
                                        this.error_max = false;
                                    }
                                }
                            });
                        }

                        if(this.error_max){
                            Vue.notify({
                                type: 'error',
                                title: 'Oops!',
                                text: 'Número supera el máximo de restricción'
                            });
                            return false;
                        }else {
                            this.numbers.push({ amount: lastItem.amount, number: this.number, amount_busted: this.amount_reventado });
                        
                            this.number = '';
                            this.amount = '';
                            this.amount_busted = '';
                            this.error_number = false;
                            this.error_amount = false;
                            this.error_reventado = false;
                            
                            this.$nextTick(() => {
                                let input = this.$refs.number;
                                input.focus();
                            });
                        }
                    }
                }else {

                    if(!this.game.game.is_monkey){
                         //validate restriction
                        this.numbers_restrictions.map((item, i) => {
                            
                            if(item == this.number){                           
                                if(parseInt(this.amount) > this.amount_restriction){
                                    this.error_max = true;
                                }else{
                                    this.error_max = false;
                                }
                            }
                        });
                    }
                   

                    if(this.error_max){
                        Vue.notify({
                            type: 'error',
                            title: 'Oops!',
                            text: 'Número supera el máximo de restricción'
                        });
                        return false;
                    }else {
                        this.numbers.push({ amount: this.amount, number: this.number, amount_busted: this.amount_reventado });
                        const lastItem = this.numbers[this.numbers.length - 1];
                        let lastIndex = this.numbers.length - 1;
                        this.mask = lastItem.amount;
                        this.number = '';
                        this.amount = '';
                        this.error_number = false;
                        this.error_amount = false;

                        this.$nextTick(() => {
                            let input = this.$refs.number;
                            input.focus();
                        });
                    }
                }
            },
            addNumber: function (e) {
                let current = e.target.value;
                
                if(current == ''){
                    if(this.numbers.length > 0){
                        const lastItem = this.numbers[this.numbers.length - 1];
                        let lastIndex = this.numbers.length - 1;
                        this.mask = lastItem.amount;

                        //validate restriction
                        if(!this.game.game.is_monkey){
                            this.numbers_restrictions.map((item, i) => {
                                if(item == this.number){
                                    if(parseInt(this.amount) > this.amount_restriction){
                                        this.error_max = true;
                                    }else{
                                        this.error_max = false;
                                    }
                                }
                            });
                        }

                        if(this.error_max){
                            Vue.notify({
                                type: 'error',
                                title: 'Oops!',
                                text: 'Número supera el máximo de restricción'
                            });
                            return false;
                        }else {
                            this.numbers.push({ amount: lastItem.amount, number: this.number, amount_busted: this.amount_reventado });
                        
                            this.number = '';
                            this.amount = '';
                            this.amount_busted = '';
                            this.error_number = false;
                            this.error_amount = false;
                            this.error_reventado = false;
                            
                            this.$nextTick(() => {
                                let input = this.$refs.number;
                                input.focus();
                            });
                        }
                    }
                }else {

                    if(!this.game.game.is_monkey){
                         //validate restriction
                        this.numbers_restrictions.map((item, i) => {
                            
                            if(item == this.number){                           
                                if(parseInt(this.amount) > this.amount_restriction){
                                    this.error_max = true;
                                }else{
                                    this.error_max = false;
                                }
                            }
                        });
                    }
                   

                    if(this.error_max){
                        Vue.notify({
                            type: 'error',
                            title: 'Oops!',
                            text: 'Número supera el máximo de restricción'
                        });
                        return false;
                    }else {
                        this.numbers.push({ amount: this.amount, number: this.number, amount_busted: this.amount_reventado });
                        const lastItem = this.numbers[this.numbers.length - 1];
                        let lastIndex = this.numbers.length - 1;
                        this.mask = lastItem.amount;
                        this.number = '';
                        this.amount = '';
                        this.error_number = false;
                        this.error_amount = false;

                        this.$nextTick(() => {
                            let input = this.$refs.number;
                            input.focus();
                        });
                    }
                }

            },
            deleteNumber: async function(index) {
                this.numbers.splice(index, 1);
                await this.$nextTick();
                if(this.numbers.length == 0){
                    this.can_sell = true;
                }else {
                    this.can_sell = false;
                }
            },
            handleNumber: function(e){
                let current = e.target.value;

                if(this.game.game.is_monkey){
                    if(current.length == 3) {
                        this.$nextTick(() => {
                            let input = this.$refs.amount;
                            input.focus();
                        });
                    } 
                }else {
                    if(current.length == 2) {
                        this.$nextTick(() => {
                            let input = this.$refs.amount;
                            input.focus();
                        });
                    } 
                }
            },
            async selectModule(modKey) {
                this.selected = modKey;
                this.game = this.myGames[modKey];
                this.hide_game = true;
                this.current_game = this.myGames[modKey].game.name;
                this.is_busted = this.myGames[modKey].game.is_busted;
            
                let res = await axios.post(`/admin/api/games/${this.game.game_id}/schedules`, {
                    date: this.date
                });


                this.showDate = true;
                //this.date = response.data.date;
                this.current_time = '';
                this.time = null;
                this.hide_date = true;
                this.showSchedule = true;
                this.hide_time = false;
                this.schedules = res.data;
            },
            async selectTime(key){
                this.time_selected = key;
                this.current_time = this.schedules[key].time;
                this.time = this.schedules[key].time;
                this.hide_time = true;
                
                if(!this.game.game.is_monkey){
                    let data = {
                        place_id: parseInt(this.placeId),
                        game_id: this.game.game_id,
                        game_time: this.current_time,
                        date: this.date
                    }

                    let res = await axios.post(`/places/api/getListByPlace`, data);

                    this.lists = res.data.list;
                    this.numbers_restrictions = res.data.numbers;
                    this.amount_restriction = res.data.restriction_amount;
                    this.showList = true;
                }

            },
            isSelected(key) {
                return key == this.selected;
            },
            isTimeSelected(key) {
                return key == this.time_selected;
            },
            toggleGame(){
                this.hide_game = false;
            },
            toggleDate(){
                this.hide_date = false;
                this.showSchedule = false;
            },
            toggleTime(){
                this.hide_time = false;
            },
            joinNumbers(numbers){
                let news = [];
                numbers.map((item) => {
                    news.push(item.number)
                });
                return news.join('-');
            },
            joinBusted(numbers){
                let news = [];
                numbers.map((item) => {
                    news.push(`${item.amount} - ${item.amount_busted}`)
                });
                return news.join('-');
            },
            joinAmount(numbers){
                let total = numbers.reduce((sum, sell) => sum + parseInt(sell.amount), 0);
                return total;
            },
            sumBusted(numbers){
                let total = numbers.reduce((sum, sell) => sum + parseInt(sell.amount_busted), 0);
                return total;
            },
            confirm(){
                let result = confirm("Está seguro que desea realizar la venta!");
                if(result){
                    this.createTicket();
                }
            },
            createTicket: async function(){
                this.show = true;

                if(this.selected == null){
                    Vue.notify({
                        type: 'error',
                        title: 'Oops!',
                        text: 'Por favor selecciona un sorteo'
                    });
                    return;
                }
                

                let loader = this.$loading.show({ canCancel: false });

                let data = {
                    place_id: parseInt(this.placeId),
                    game_id: this.game.game_id,
                    time: this.current_time,
                    date: this.date,
                    numbers: this.numbers,
                    is_busted: this.is_busted
                }

                try {

                    let response = await axios.post(`/places/api/createTicketList`, data);

                   
                    this.showTicket = true;
                    this.ticketCreated = response.data;
                    loader.hide();
                
                    let listData = {
                        place_id: parseInt(this.placeId),
                        game_id: this.game.game_id,
                        game_time: this.current_time,
                        date: this.date
                    }

                    let res = await axios.post(`/places/api/getListByPlace`, listData);

                    this.lists = res.data.list;
                    this.numbers_restrictions = res.data.numbers;
                    this.amount_restriction = res.data.restriction_amount;

                    let dataSave = {
                        ...data,
                        current_game: this.current_game,
                        current_time: this.current_time,
                        date: this.date,
                        game: this.game,
                        selected: this.selected,
                        time_selected: this.time_selected
                    }

                    // localStorage.setItem('ticket', JSON.stringify(dataSave));

                    this.$nextTick(() => {
                        this.print();
                    });
                    
                } catch (error) {
             
                    loader.hide();
                    this.showError = true;
                    this.errorList = error.response.data.data
                    Vue.notify({
                        type: 'error',
                        title: 'Oops!',
                        text: 'Algo ha salido mal'
                    });
                }

            },
            clearAll: function(){
                this.current_game = '';
                this.current_time = '';
                this.number = '';
                this.amount = '';
                this.numbers = [];
                this.ticketCreated = null;
                this.date = '';
                this.showTicket = false;
                this.showDate = false;
                this.showSchedule = false;
                this.showList = false;
                this.schedules = [];
                this.game = null;
                this.hide_game = false;
                this.hide_time = false;
                this.selected = null;
                this.time_selected = null;
                this.mask = '';
            },
            print: function() {

                window.location.href = `/places/print/${this.ticketCreated.number}`;
            }
        }
    }
</script>
