<template>
    <div id="printMe">
        <div class="print_logo">
            <img src="https://pegatiempos.com/img/logo_print.png" alt="PegaTiempos">
        </div>
       <div class="print_detail">
            <strong>{{ place.name }}</strong>
        </div>
        <div class="print_buy">
            <span>{{ currentGame.name }} - {{ currentDate }}</span>
        </div>
        <div class="print_head">
            <strong>TIQUETE #</strong>
            <strong>MONTO</strong>
        </div>
        <div class="print_list">
            <div class="print_list_item" v-for="(ticket, index) in allTickets" :key="index">
                <div>{{ ticket.ticket_number }}</div>
                <span>{{ ticket.prize|toCurrency }}</span>
            </div>
        </div>
        <div class="print_head">
            <strong>TOTAL</strong>
            <strong class="print_head_total">{{ allTotal|toCurrency }}</strong>
        </div>
        <div class="print_thanks">
            <span style="font-size: 12px">Nota: "Verificar codigo cuando pague un premio"</span>
        </div>
    </div>
</template>
<script>
    import { validationMixin } from "vuelidate"
    import moment from 'moment';
    import _ from 'lodash';
    export default {
        mixins: [validationMixin],
        props: ["tickets", "game", "total", "place", "date"],
        data: function() {
            return {
                ticket: this.data,
                currentGame: this.game,
                allTotal: this.total,
                allTickets: this.tickets,
                currentDate: this.date
            }
        },
        filters: {
            toCurrency (value) {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
        },
        created() {
            //
        },
        mounted() {
            console.log(this.tickets)
        },
        watch: {
           
        },
        computed: {
            keymap() {
                return {
                    "*": this.confirm,
                };
            },
            timestamp: function () {
                return moment().format('DD/MM/YYYY hh:mm a')
            },
            sellings() {
                return _.groupBy(this.ticket.list, 'number') 
            },
            groups() {
                return _.groupBy(this.ticket.list, 'amount') 
            },
        },
        methods: {
            joinNumbers(numbers){
                let news = [];
                numbers.map((item) => {
                    news.push(item.number)
                });
                return news.join('-');
            },
            joinBusted(numbers){
                let news = [];
                numbers.map((item) => {
                    news.push(`${item.amount}-${item.amount_busted}`)
                });
                return news.join('-');
            },
            joinAmount(numbers){
                let total = numbers.reduce((sum, sell) => sum + parseInt(sell.amount), 0);
                return total;
            },
            sumBusted(numbers){
                let total = numbers.reduce((sum, sell) => sum + parseInt(sell.amount_busted), 0);
                return total;
            },
            confirm(){
                let result = confirm("El ticket a imprimir es una copia!");
                if(result){
                    this.print();
                }
            },
            print: function(){
                window.print();
            }
        }
    }
</script>
