<template>
    <div class="row">
      <template v-if="showBoxFilter">
        <div class="col-md-6 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <h6 class="card-title">Reportes</h6>
              <form action="" method="GET">
                <div class="form-group">
                  <label><strong>Tipos de filtro</strong></label>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" name="filter" v-on:change="selectTypeFilter" value="date_puestos">
                      Por fecha y puestos agrupados
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" name="filter" v-on:change="selectTypeFilter" value="dates_global">
                      Por fechas agrupados
                    </label>
                  </div>
                </div>

                <div class="form-group" v-if="showFilter1">
                  <input type="checkbox" value="all" @click="addAll">
                  <label for="all">Seleccionar Todos</label>
                  <div v-for="(place, index) in places" :key="index">
                    <input type="checkbox" :id="place.id" v-bind:value="place.id" v-model="checkedPlaces">
                    <label for="jack">{{ place.name }}</label>
                  </div>
                </div>

                <div class="form-group" v-if="showPlaces">
                  <label><strong>Seleccionar sorteo</strong></label>
                  <div class="report_lists_games">
                        <div 
                            :class="[
                                index == selectedGameIndex ? 'report_lists_game active' : 'report_lists_game inactive',
                            ]"
                            v-for="(game, index) in games" :key="index" @click="selectGame(game.game, index)">
                            {{ game.game.name }}
                        </div>
                  </div>
                </div><!--/form-group-->

                <div class="form-group" v-if="showSchedule">
                  <label><strong>Seleccionar Horario</strong></label>
                  <div class="report_lists_games">
                        <div 
                            :class="[
                                index == selectedScheduleIndex ? 'report_lists_game active' : 'report_lists_game inactive',
                            ]"
                            v-for="(schedule, index) in schedules" :key="index" @click="selectSchedule(schedule, index)">
                            {{ schedule.time }}
                        </div>
                  </div>
                </div><!--/form-group-->

                <div class="form-group" v-if="showCalendar">
                  <label><strong>Seleccionar Fecha</strong></label>
                    <div class="row">
                      <div class="col-md-6">
                        <DatePicker 
                            id="from" 
                            label="Desde" 
                            locale="es" 
                            only-date 
                            auto-close
                            format="DD-MM-YYYY"
                            formatted="DD-MM-YYYY" 
                            v-model="validFrom" 
                        />
                      </div>
                      <div class="col-md-6">
                        <DatePicker 
                            id="to" 
                            label="Hasta" 
                            locale="es" 
                            only-date 
                            auto-close
                            format="DD-MM-YYYY"
                            formatted="DD-MM-YYYY" 
                            v-model="validTo" 
                        />
                      </div>
                    </div>
                </div><!--/form-group-->

                <div class="form-group">
                  <button @click="handleFilter" class="btn btn-block btn-primary btn-login">Filtrar datos</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </template>

      <template v-if="showFiltering">
        <div class="col-md-12 grid-margin stretch-card">
          <div class="card">
              <div class="card-body">
                <h6 class="card-title">Reportes por fechas y puestos</h6>
                <div class="table-filtering">
                  <div class="filter_detail">
                    <div class="filter_list_head_2">
                        <span></span>
                        <strong>Nº Ganador</strong>
                        <strong>Venta Real</strong>
                        <strong>Comision Puesto</strong>
                        <strong>Comision Banca</strong>
                        <strong>Premio</strong>
                        <strong>Balance</strong>
                        <strong>Ganancia</strong>
                    </div>

                    <div class="filter_list" v-for="(report, index) in reports.data" :key="index">
                      <strong>{{ report.place }}</strong>
                      
                      <div class="filter_list_items" v-for="(item, i) in report.data" :key="i">
                          <div class="filter_list_item_2" v-for="(value, j) in item.data" :key="j">
                            <span class="text-left">{{ i | formatDate }} - {{ value.game }} - {{ value.time | formatTime }}</span>
                            <span>{{ value.number }}</span>
                            <span>₡ {{ value.sales | toCurrency }}</span>
                            <span>₡ {{ value.comission_place | toCurrency }}</span>
                            <span>₡ {{ value.comission_banking | toCurrency }}</span>
                            <span>₡ {{ value.prize | toCurrency }}</span>
                            <span>₡ {{ value.balance| toCurrency }}</span>
                            <span>₡ {{ value.comission_banking - value.comission_place |toCurrency }}</span>
                          </div>
                          
                          <div class="filter_list_foot_2" v-if="item.total_sales > 0">
                            <span></span>
                            <span></span>
                            <span>₡ {{ item.total_sales | toCurrency }}</span>
                            <span>₡ {{ item.total_places | toCurrency }}</span>
                            <span>₡ {{ item.total_comission | toCurrency }}</span>
                            <span>₡ {{ item.total_prize | toCurrency }}</span>
                            <span>₡ {{ item.total_balance|toCurrency }}</span>
                            <span>₡ {{ item.total_earning|toCurrency }}</span>
                          </div>
                      </div><!--/filter_list_items-->
                    </div><!--/filter_list-->

                    <div class="filter_list_foot_2">
                      <strong class="text-left">Total General</strong>
                      <span></span>
                      <span>₡ {{ reports.gran_total_sales|toCurrency }}</span>
                      <span>₡ {{ reports.gran_total_place|toCurrency }}</span>
                      <span>₡ {{ reports.gran_total_comission|toCurrency }}</span>
                      <span>₡ {{ reports.gran_total_prize|toCurrency }}</span>
                      <span>₡ {{ reports.gran_total_balance|toCurrency }}</span>
                      <span>₡ {{ reports.gran_total_earning|toCurrency }}</span>
                    </div><!--/filter_list_foot-->
                    
                  </div>
                </div>
              </div>
          </div>
        </div>
      </template>

      <template v-if="showFilteringGlobal">
        <div class="col-md-12 grid-margin stretch-card">
          <div class="card">
              <div class="card-body">
                <h6 class="card-title">Reportes por fechas</h6>
                <div class="table-filtering">
                  <div class="filter_detail">
                    <div class="filter_list_head_2">
                        <span></span>
                        <span></span>
                        <strong>Venta Real</strong>
                        <strong>Comision Puesto</strong>
                        <strong>Comision Banca</strong>
                        <strong>Premios</strong>
                        <strong>Balance</strong>
                        <strong>Ganancia</strong>
                    </div>

                    <div class="filter_list" v-for="(report, index) in reports.data" :key="index">
                      <strong>{{ report.place }}</strong>
                      <div class="filter_list_items">
                          <div class="filter_list_item_2" v-for="(item, i) in report.data" :key="i">
                            <span class="text-left">{{ i | formatDate }}</span>
                            <span></span>
                            <span>₡ {{ item.total_sales | toCurrency }}</span>
                            <span>₡ {{ item.place_comission | toCurrency }}</span>
                            <span>₡ {{ item.banking_comission | toCurrency }}</span>
                            <span>₡ {{ item.prize | toCurrency }}</span>
                            <span>₡ {{ item.balance | toCurrency }}</span>
                            <span>₡ {{ item.earning | toCurrency }}</span>
                          </div>
                          <div class="filter_list_foot_2">
                            <span></span>
                            <span></span>
                            <span>₡ {{ report.total_sales | toCurrency }}</span>
                            <span>₡ {{ report.total_places | toCurrency }}</span>
                            <span>₡ {{ report.total_banking | toCurrency }}</span>
                            <span>₡ {{ report.total_prize | toCurrency }}</span>
                            <span>₡ {{ report.total_balance | toCurrency }}</span>
                            <span>₡ {{ report.total_earning | toCurrency }}</span>
                          </div>
                      </div><!--/filter_list_items-->
                    </div><!--/filter_list-->

                    <div class="filter_list_foot_2">
                      <strong class="text-left">Total General</strong>
                      <span></span>
                      <span>₡ {{ reports.grand_total | toCurrency }}</span>
                      <span>₡ {{ reports.grand_place | toCurrency }}</span>
                      <span>₡ {{ reports.grand_comission | toCurrency }}</span>
                      <span>₡ {{ reports.grand_prize | toCurrency }}</span>
                      <span>₡ {{ reports.grand_balance | toCurrency }}</span>
                      <span>₡ {{ reports.grand_earning | toCurrency }}</span>
                    </div><!--/filter_list_foot-->

                  </div>
                </div>
              </div>
          </div>
        </div>
      </template>

    </div>   
</template>

<script>
    import { required, minLength, between } from 'vuelidate/lib/validators'
    export default {
        props: ["userId", "id"],
        data: function() {
            return {
                adminId: this.id,
                places: [],
                games: [],
                schedules: [],
                checkedPlaces: [],
                type_filter: '',
                validFrom: null,
                validTo: null,
                showGame: false,
                showCalendar: false,
                showPlaces: false,
                showSchedule: false,
                currentPlace: null,
                selectedPlaceIndex: -1,
                currentGame: null,
                selectedGames: [],
                selectedGameIndex: -1,
                currentSchedule: null,
                selectedScheduleIndex: -1,
                showBoxFilter: true,
                showFiltering: false,
                showFilteringGlobal: false,
                showFilter1: false,
                singleFilter: null,
                reports: null
            }
        },
        filters: {
            toCurrency (value) {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
        },
        created() {
            //
        },
        mounted() {
            
        },
        computed: {
        },
        methods: {
            addAll(){
                this.places.map((place, i) => {
                  this.checkedPlaces.push(place.id)
                })
            },
            selectTypeFilter(e){
                let selection = e.target.value;
                this.type_filter = selection;
                if(selection == 'puestos'){
                    this.getPlaces();
                    this.showPlaces = true;
                }else if(selection == 'date_puestos' || selection == 'dates_global'){
                  this.getPlaces();
                  this.showFilter1 = true;
                  this.showCalendar = true;
                }else{
                  this.showCalendar = true;
                }
            },
            async getPlaces(){
                let response = await axios.get(`/admin/api/places/${this.adminId}`);
                this.places = response.data;
            },
            async getGamesByPlace(id){
                let response = await axios.get(`/admin/api/place/${id}/games`);
                this.games = response.data;
            },
            async getGames(){
                let response = await axios.get(`/admin/api/games`);
                this.games = response.data;
            },
            async getSchedulesByGame(id){
                let response = await axios.get(`/admin/api/games/${id}/schedules`);
                this.schedules = response.data;
            },
            async selectPlace(place, index){
                this.selectedPlaceIndex = index;
                this.currentPlace = place;
                this.getGamesByPlace(place.id);
                this.showGame = true;
            },
            async selectGame(game, index){
              this.selectedGameIndex = index;
              this.currentGame = game;
              this.getSchedulesByGame(game.id);
              this.showSchedule = true;
              this.selectedScheduleIndex = -1;
            },
            async selectSchedule(schedule, index){
                this.selectedScheduleIndex = index;
                this.currentSchedule = schedule;
                this.showCalendar = true;
            },
            calculateSum(items){
              var sum = 0;
              items.forEach(function(item) {
                sum += item.sales;
              });
              return sum;
            },
            calculateSumGlobal(data){
              let total = [];
              Object.entries(data).forEach(([key, val]) => {
                  total.push(val.total_sales)
              });
             return total.reduce(function(total, num){ return total + num }, 0);
            },
            async handleFilter(e){
                e.preventDefault();

                if(this.type_filter == 'date_puestos'){

                    let data = {
                      filter: 'date_puestos',
                      placesIds: this.checkedPlaces,
                      validFrom: this.validFrom,
                      validTo: this.validTo  
                    }
                    let res = await axios.post(`/admin/api/reports`, data);
                    this.reports = res.data;
                    this.showFiltering = true;
                    this.showBoxFilter = false;
                    
                }else if(this.type_filter == 'dates_global'){

                  let data = {
                      filter: 'dates_global',
                      placesIds: this.checkedPlaces,
                      validFrom: this.validFrom,
                      validTo: this.validTo  
                  }
                  let res = await axios.post(`/admin/api/reports`, data);
                  this.reports = res.data;
                  this.showFilteringGlobal = true;
                  this.showBoxFilter = false;
                }
            }
        }
    }
</script>
