<template>
    <div class="row">
        <div class="col-12 col-lg-6">
            <div class="playing_content">
                <div class="content_steps">
                    <div class="step">
                        <div class="step_info" :class="[hide_game ? 'selected' : '']">  
                            <strong v-if="!hide_game">Seleccionar Sorteo</strong>
                            <span v-if="hide_game">{{ current_game }}</span>
                            <span v-if="hide_game" class="edit_icon" @click="toggleGame">
                                <img :src="picture" alt="Edit Icon" class="tiny_icon">
                            </span>
                        </div>
                        <div class="my_games" v-if="!hide_game">
                            <div 
                            :class="[
                                isSelected(key) ? 'my_game_item pointer selected' : 'my_game_item pointer',
                            ]"
                            v-for="(game, key) in myGames" :key="game.id" @click="selectModule(key)">
                                <strong>Paga {{ game.payment_game }} veces</strong>
                                <span>{{ game.game.name }}</span>
                            </div>
                        </div><!--/my_games-->
                    </div>

                    <div class="step" v-if="showDate">
                        <div class="step_info" :class="[hide_date ? 'selected' : '']">  
                            <strong v-if="!hide_date">Seleccionar Fecha</strong>
                            <span v-if="hide_date">{{ date }}</span>
                            <span v-if="hide_date" class="edit_icon" @click="toggleDate">
                                <img :src="picture" alt="Edit Icon" class="tiny_icon">
                            </span>
                        </div>
                        <div class="step_date" v-if="!hide_date">
                            <DatePicker 
                                id="date" 
                                label="Seleccionar Fecha" 
                                locale="es" 
                                only-date 
                                auto-close
                                format="DD-MM-YYYY"
                                formatted="DD-MM-YYYY" 
                                v-model="date" 
                            />
                        </div>
                    </div>

                    <div class="step" v-if="showSchedule">
                        <div class="step_info" :class="[hide_time ? 'selected' : '']">  
                            <strong v-if="!hide_time">Seleccionar Fecha y Hora</strong>
                            <span v-if="hide_time">{{ current_time }}</span>
                            <span v-if="hide_time" class="edit_icon" @click="toggleTime">
                                <img :src="picture" alt="Edit Icon" class="tiny_icon">
                            </span>
                        </div>
                        <div class="my_games" v-if="!hide_time">
                            <div :class="[isTimeSelected(index) ? 'my_game_item pointer selected' : 'my_game_item pointer']" 
                                v-for="(schedule, index) in schedules" :key="schedule.id" @click="selectTime(index)">
                                <span>{{ schedule.time }}</span>
                            </div>
                        </div><!--/my_games-->
                    </div>
                    <div class="step">
                        <div class="step_info">  
                            <strong>Juega y Gana</strong>
                        </div>

                        <div class="number_listing" v-if="numbers.length > 0">
                            <div class="number_playing" v-for="(number, index, i) in sellings" :key="index">
                                <button @click="deleteNumber(i)">X</button>
                                <div class="circle">{{ index }}</div>
                                <span>₡ {{ joinAmount(number)|toCurrency }}</span>
                            </div>
                        </div>
                    
                        <div  class="numbers_input">
                            <input 
                                type="number"
                                :ref="'number'" 
                                v-model="number" 
                                @input="handleNumber($event)">
                            <input 
                                type="number"
                                :ref="'amount'" 
                                v-model="amount"
                                :placeholder="[[ mask ]]"
                                v-on:keyup.enter="addNumber($event)">                 
                        </div>

                        <div class="total_sell">
                            Venta total: ₡ {{ total|toCurrency }}
                        </div>
                        <button class="btn btn-block btn-app btn-primary mt-3" @click="restart">RESTART</button>
                        <button class="btn btn-block btn-app btn-primary mt-3" @click="createTicket">VENDER TICKET</button>
                        <button v-if="showTicket" class="btn btn-block btn-app btn-info mt-4" @click="print">Imprimir Ticket</button>
                    </div>
                </div>

                <div id="printMe">
                    <div class="print_logo">
                        <img :src="logo" alt="GanaTiempos">
                    </div>
                    <div class="print_detail">
                        <strong>{{ name }}</strong>
                    </div>
                    <div class="print_buy">
                        <span>{{ timestamp }}</span>
                        <span>{{ current_game }}</span>
                        <span>{{ date|formatDate }} - {{ time|formatTime }}</span>
                    </div>
                    <div class="print_head">
                        <strong>Nº</strong>
                        <strong>APUESTA</strong>
                    </div>
                    <div class="print_list">
                        <div class="print_list_item" v-for="(number, index) in groups" :key="index">
                            <div>
                                {{ joinNumbers(number) }}
                            </div>
                            <span>{{ index }}</span>
                        </div>
                    </div>
                    <div class="print_head">
                        <strong>TOTAL</strong>
                        <strong>{{ total| toCurrency }}</strong>
                    </div>
                    <div class="print_thanks">
                        <span>¡Gracias por jugar!</span>
                        <span>GanaTiempos</span>
                    </div>
                    <div class="print_number" v-if="ticketCreated">
                        <span>Ticket: {{ ticketCreated.number }}</span>
                    </div>
                </div>
            </div>
        </div>
        <span v-hotkey="keymap"></span>
        <div class="col-12 col-lg-6" v-if="showList">
            <div class="number_list">
                <div class="number_list_item" v-for="(list, index) in lists" :key="index">
                    <span>{{ list.number }}</span>
                    <span>₡{{ list.bet }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import { mapMutations } from "vuex";
    import { validationMixin } from "vuelidate"
    import { required, minLength, between } from 'vuelidate/lib/validators'
    import moment from 'moment';
    import _ from 'lodash';

    function getDefaultData() {
        return {
            placeId: this.id,
            selected: null,
            time_selected: null,
            schedules: [],
            game: null,
            time: null,
            date: null,
            show: false,
            showDate: false,
            showSchedule: false,
            showTicket: false,
            hide_game: false,
            hide_date: false,
            current_game: '',
            hide_time: false,
            current_time: '',
            showList: false,
            numbers: [],
            number: '',
            amount: '',
            ticketCreated: null,
            lists: [],
            mask: '',
            prints: []
        }
    }

    export default {
        mixins: [validationMixin],
        props: ["id", "picture", "logo", "name"],
        data: function() {
            return {
                placeId: this.id,
                selected: null,
                time_selected: null,
                schedules: [],
                game: null,
                time: null,
                date: null,
                show: false,
                showDate: false,
                showSchedule: false,
                showTicket: false,
                hide_game: false,
                hide_date: false,
                current_game: '',
                hide_time: false,
                current_time: '',
                showList: false,
                numbers: [],
                number: '',
                amount: '',
                ticketCreated: null,
                lists: [],
                mask: '',
                prints: [],
                componentKey: 0,
            }
        },
        validations: {
            numbers:{
                $each: {
                    number: {
                        required,
                        minLength: minLength(2),
                        between: between(0, 99)
                    },
                    amount: {
                        required,
                    },
                }
            },
        },
        filters: {
            toCurrency (value) {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
        },
        created() {
            //
        },
        mounted() {
            this.getGamesByPlace();
        },
        watch: {
            date: function (val) {
               this.hide_date = true;
               this.showSchedule = true;
            },

        },
        computed: {
            ...mapGetters(["myGames"]),
            keymap() {
                return {
                    "8": this.confirm,
                    "*": this.confirm,
                };
            },
            total: function() {
                if (!this.numbers) {
                    return 0;
                }

                return this.numbers.reduce(function (total, value) {
                    return total + Number(value.amount);
                }, 0);
            },
            timestamp: function () {
                return moment().format('DD/MM/YYYY hh:mm a')
            },
            sellings() {
                return _.groupBy(this.numbers, 'number') 
            },
            groups() {
                return _.groupBy(this.numbers, 'amount') 
            }
        },
        methods: {
            ...mapMutations(["setMyGames"]),
            async getGamesByPlace(id) {
                let res = await axios.get(`/admin/api/place/${this.placeId}/games`);
                this.setMyGames(res.data);
            },
            validateState(item, index) {
                const { $dirty, $error, $model } = item
                if($dirty) {
                    return !$error;
                }else {
                    if($model.length == 2){
                        return this.$nextTick(() => {
                            let input = this.$refs.amount[index];
                            input.focus();
                        });
                    }
                }
            },
            addNumber: function (e) {
                let current = e.target.value;

                if(current == ''){
                    if(this.numbers.length > 0){
                        const lastItem = this.numbers[this.numbers.length - 1];
                        let lastIndex = this.numbers.length - 1;
                        this.mask = lastItem.amount;
                        
                        this.numbers.push({ amount: lastItem.amount, number: this.number });
                        
                        this.number = '';
                        this.amount = '';
                        
                        this.$nextTick(() => {
                            let input = this.$refs.number;
                            input.focus();
                        });
                    }
                }else {

                    this.numbers.push({ amount: this.amount, number: this.number });
                    const lastItem = this.numbers[this.numbers.length - 1];
                    let lastIndex = this.numbers.length - 1;
                    this.mask = lastItem.amount;
                    this.number = '';
                    this.amount = '';

                    this.$nextTick(() => {
                        let input = this.$refs.number;
                        input.focus();
                    });
                }

            },
            deleteNumber: function(index) {
                this.numbers.splice(index, 1);
            },
            handleNumber: function(e){
                let current = e.target.value;

                if(current.length == 2) {
                    this.$nextTick(() => {
                        let input = this.$refs.amount;
                        input.focus();
                    });
                } 
            },
            async selectModule(modKey) {
                this.selected = modKey;
                this.game = this.myGames[modKey];
                this.hide_game = true;
                this.current_game = this.myGames[modKey].game.name;
                let res = await axios.get(`/admin/api/games/${this.game.game_id}/schedules`);
                this.showDate = true;
                this.date = moment().format('DD-MM-YYYY');
                this.hide_date = true;
                this.showSchedule = true;
                this.schedules = res.data;
            },
            async selectTime(key){
                this.time_selected = key;
                this.current_time = this.schedules[key].time;
                this.time = this.schedules[key].time;
                this.hide_time = true;

                let data = {
                    place_id: parseInt(this.placeId),
                    game_id: this.game.game_id,
                    game_time: this.current_time
                }

                let res = await axios.post(`/places/api/getListByPlace`, data);
                this.lists = res.data;
                this.showList = true;

            },
            isSelected(key) {
                return key == this.selected;
            },
            isTimeSelected(key) {
                return key == this.time_selected;
            },
            toggleGame(){
                this.hide_game = false;
            },
            toggleDate(){
                this.hide_date = false;
                this.showSchedule = false;
            },
            toggleTime(){
                this.hide_time = false;
            },
            joinNumbers(numbers){
                let news = [];
                numbers.map((item) => {
                    news.push(item.number)
                });
                return news.join('-');
            },
            joinAmount(numbers){
                let total = numbers.reduce((sum, sell) => sum + parseInt(sell.amount), 0);
                return total;
            },
            confirm(){
                let result = confirm("Está seguro que desea realizar la venta!");
                if(result){
                    this.createTicket();
                }
            },
            createTicket: async function(){
                this.show = true;

                if(this.selected == null){
                    Vue.notify({
                        type: 'error',
                        title: 'Oops!',
                        text: 'Por favor selecciona un sorteo'
                    });
                    return;
                }                

                this.$v.numbers.$touch()
                if (this.$v.numbers.$anyError){
                    Vue.notify({
                        type: 'error',
                        title: 'Oops!',
                        text: 'Por favor corregir los errores'
                    });
                    return false
                }

                let loader = this.$loading.show({ canCancel: false });
                let data = {
                    place_id: parseInt(this.placeId),
                    game_id: this.game.game_id,
                    time: this.time,
                    date: this.date,
                    numbers: this.numbers
                }

                try {

                    let response = await axios.post(`/places/api/createTicketList`, data);
                    this.showTicket = true;
                    this.ticketCreated = response.data;
                    loader.hide();
                    this.print();
                    

                    let listData = {
                        place_id: parseInt(this.placeId),
                        game_id: this.game.game_id,
                        game_time: this.current_time
                    }

                    let res = await axios.post(`/places/api/getListByPlace`, listData);
                    this.lists = res.data;
                    
                } catch (error) {
                    loader.hide();
                    Vue.notify({
                        type: 'error',
                        title: 'Oops!',
                        text: 'Algo ha salido mal'
                    });
                }

            },
            print: function() {
                this.$htmlToPaper('printMe');
            },
            restart: function(){
                this.$parent.restart();
            }
        }
    }
</script>
