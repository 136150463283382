<template>
    <div class="row">
        <div class="col-md-5 grid-margin stretch-card">
            
            <div class="card">
                <div class="card-body">

                    <div class="mb-2">
                        <strong class="mb-3 d-block">Seleccionar Sorteo</strong>
                        <div class="step">
                            <div class="my_games">
                                <div :class="[ isSelected(key) ? 'my_game_item pointer selected' : 'my_game_item pointer']"
                                v-for="(game, key) in myGames" :key="game.id" @click="selectGame(key)">
                                    <strong>{{ game.name }}</strong>
                                </div>
                            </div><!--/my_games-->
                        </div>
                    </div>

                    <div class="mt-3" v-if="showSchedule">
                        <strong class="mb-3 d-block">Seleccionar Horario</strong>
                        <div class="step">
                            <div class="my_times">
                                <div 
                                    :class="[
                                        isTimeSelected(index) ? 'my_time_item pointer selected' : 'my_time_item pointer'
                                    ]" 
                                    v-for="(schedule, index) in schedules" :key="schedule.id" 
                                    @click="selectTime(index)">
                                    <span>{{ schedule.time|formatTime }}</span>
                                </div>
                            </div><!--/my_times-->
                        </div>
                    </div>

                </div>
            </div>

            
        </div>
        <div class="col-md-7" v-if="this.showList">
            <div class="number_list">
                <div :class="[list.restriction ? 'number_list_item restrcited' : 'number_list_item']" v-for="(list, index) in lists" :key="index">
                    <span>{{ list.number }}</span>
                    <span  :class="[list.amount >= 20000 ? 'badge badge-danger' : '']">₡ {{ formatPrice(list.amount) }}</span>
                </div>
            </div>

           <div class="mt-4">
                <strong>Total Lista: ₡{{ formatPrice(total) }}</strong>
           </div>

        </div>
    </div>
</template>

<script>
    export default {
        props: [],
        data: function() {
            return {
                myGames: [],
                schedules: [],
                lists: [],
                selected: null,
                time_selected: null,
                game: null,
                time: null,
                places: [],
                balances: [],
                currentPlace: null,
                place: '',
                amount: '',
                type: '',
                current_time: '',
                loading: false,
                showDetail: false,
                showSchedule: false,
                showList: false,
                total: 0
            }
        },
        filters: {
            toCurrency (value) {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
        },
        created() {
            //
        },
        async mounted() {
            await this.getGames();
        },
        computed: {
        },
        methods: {
            isSelected(key) {
                return key == this.selected;
            },
             isTimeSelected(key) {
                return key == this.time_selected;
            },
            async getGames(id) {
                let res = await axios.get(`/admin/api/games`);
                this.myGames = res.data;
            },
            async getPlaces(id) {
                let res = await axios.get(`/admin/api/places`);
                this.places = res.data;
            },
            async getBalancesByPlace(id) {
                let res = await axios.get(`/admin/api/balances/${id}`);
                this.balances = res.data;
                this.showDetail = true;
            },
            async selectGame(modKey) {
                this.selected = modKey;
                this.game = this.myGames[modKey];
                

                let res = await axios.get(`/admin/api/games/${this.game.id}/allSchedules`);
                this.schedules = res.data;

                this.showSchedule = true;

            },
            async selectTime(key){
                this.time_selected = key;
                this.current_time = this.schedules[key].time;
                this.time = this.schedules[key].time;
                
                let data = {
                    game_id: this.game.id,
                    game_time: this.current_time,
                }

                let res = await axios.post(`/admin/api/getListAdmin`, data);

                this.lists = res.data.list;
                this.total = res.data.total;
                this.showList = true;

            },
            handlePlace: async function(e){
                let placeId = e.target.value;
                let current = this.places.find(place => place.id == placeId);
                this.currentPlace = current;
                this.getBalancesByPlace(placeId);
            },
            handleBusted: async function(e){
                if(e.target.value == "yes"){
                    this.pay_busted = true;
                }else {
                    this.pay_busted = false;
                }
            },

            formatPrice(value) {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },
        }
    }
</script>
