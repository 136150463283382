const state = {
    games: [],
    myGames: [],
    game: null,
    placeId: null,
    user: null,
    place: null
};

const getters = {
    games: (state) => state.games,
    myGames: (state) => state.myGames,
    getGame: (state) => {
        return state.game
    },
    getPlaceId: (state) => {
        return state.placeId
    },
    getUser: (state) => {
        return state.user
    },
    getPlace: (state) => {
        return state.place
    },
};

const mutations = {
	setGames(state, modelData) {
        state.games = modelData
    },
    setMyGames(state, modelData) {
		Vue.set(state, 'myGames', modelData);
    },
    setPlaceId(state, placeId) {
        state.placeId = parseInt(placeId)
    },
    setPlace(state, place) {
        state.place = place
    },
    setUser(state, user) {
        state.user = user
    },
    setGame(state, game){
        state.game = game
    },
    appendGames(state, data) {
        console.log('appendGames ', data);
        return (state.games = data);
    },
};

const actions = { 
    async createGame({ commit }, payload) {
		try {
			let response = await axios.post(`/admin/api/addGameToPlace`, payload);
            commit('appendGames', response.data);
			Vue.notify({
				type: 'success',
				title: 'Correcto',
				text: 'Se ha agregado el sorteo correctamente!'
			});
		} catch (error) {
			Vue.notify({
				type: 'error',
				title: 'Oops',
				text: 'Algo ha salido mal!'
			});
		}
	},
};

export default {
	state,
	getters,
	mutations,
	actions
};