<template>
    <strong v-if="place !== null" :class="[place.balance < 0 ? 'pl-1 text-danger' : 'pl-1 text-success']">₡ {{ formatPrice(place.balance) }}</strong>
</template>

<script>
    export default {
        props: ["placeId"],
        data: function() {
            return {
                place: null
            }
        },
        mounted() {
            this.getBalance();
            this.watchBalance();
        },
        methods: {
            async getBalance(){
                this.callBalance();
            },
            async callBalance(){
                let res = await axios.post(`/places/api/me`, { user_id: parseInt(this.placeId) });
                this.place = res.data;
            },
            async watchBalance(){
                
                let pusher = new Pusher('5148d6ef5727a6f02ea6', {
                    cluster: 'us2'
                });

                let channel = pusher.subscribe('balances-channel');

                var that = this;
               
                channel.bind('update-balance', function(data) {
                    that.callBalance();
                });
            },
            formatPrice(value) {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },
        }
    }
</script>
