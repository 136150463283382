<template>
    <b-modal id="modal-add-game" title="Agregar Sorteo" no-fade centered size="sm" v-cloak @ok="addGame" @show="resetModal" @hidden="resetModal" :hide-footer="false" :ok-disabled="okDisabled">
        <div v-if="this.getGame !== null" class="game_name">
            {{ this.getGame.name }}
        </div>
        <form ref="form" @submit.stop.prevent="handleSubmit">
            <div class="modal_error" v-if="error">Por favor revisar los errores</div>
            <div class="modal_error" v-if="maxUse">Use una comisión menor a  {{ this.getUser.comission }}%</div>
            
            <div class="form-group">
                <label>Cuanto paga el sorteo</label>
                <input :class="$v.payment.$error ? 'form-control is-error' : 'form-control'" v-model="$v.payment.$model">
            </div>

            <div class="form-group" v-if="this.getGame.is_busted">
                <label>Cuanto paga el reventado</label>
                <input :class="$v.payment.$error ? 'form-control is-error' : 'form-control'" v-model="$v.payment_busted.$model">
            </div>

            <div class="form-group">
                <label>Comisión del sorteo (%)</label>

                <div v-if="this.getPlace">
                    <span class="comission_super" v-if="this.getPlace.owner == 'super'">Su comisión es: {{ this.getPlace.comission }}% dejar el campo en 0</span>
                </div>
                
                <input 
                    :class="$v.percent.$error ? 'form-control is-error' : 'form-control'" 
                    v-model="$v.percent.$model" 
                    @input="handleMax($event)">
            </div>

        </form>
    </b-modal>
</template>
<script>
    import { required, minLength, between } from 'vuelidate/lib/validators'
    import { mapGetters } from "vuex";
    import { mapMutations } from "vuex";
    import {mapActions} from "vuex"
    export default {
        components: {

        },
        props: {
        },
        data: function() {
            return {
                payment: '',
                payment_busted: '',
                percent: '',
                error: false,
                maxUse: false
            }
        },
        validations:{
            payment:{
                required
            },
            payment_busted: {
                required: false
            },
            percent: {
                required
            }
        },
        created() {
            //
        },
        mounted() {
            console.log('mounted place: ', this.getPlace)
        },
        beforeDestroy() {
        },
        computed: {
            ...mapGetters(["getGame", "getPlaceId", "getUser", "getPlace"]),
            okDisabled: function() {
                return false;
            },
        },
        methods: {
             ...mapMutations(["setGames"]),
             ...mapActions(["createGame"]),
            checkFormValidity() {
                const valid = this.$refs.form.checkValidity()
                this.paymentState = valid;
                return valid
            },
            resetModal() {
                this.payment = ''
                this.paymentState = null;
                this.percent = ''
            },
            addGame(bvModalEvt) {
                bvModalEvt.preventDefault()
                this.handleSubmit()
            },
            handleMax(e){
                let value = e.target.value;
                let comission = this.getUser.comission;
                let role = this.getPlace.owner;

                if(role !== 'super'){
                    if(value >= comission) {
                        this.maxUse = true;
                    }else {
                        this.maxUse = false;
                    }
                }
            },
            async handleSubmit(){
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.error = true;
                }else {

                    let loader = this.$loading.show({ 
                        canCancel: false,
                        container: this.$refs.form
                    });

                    let data = {
                        place_id: this.getPlaceId,
                        game_id: this.getGame.id,
                        payment_game: parseInt(this.payment),
                        payment_busted: parseInt(this.payment_busted) || 0,
                        percent_game: parseInt(this.percent)
                    }

                    await this.createGame(data); 
                    loader.hide();

                    setTimeout(() => this.$bvModal.hide('modal-add-game'), 100);
                }
            }
        },
    }
</script>
