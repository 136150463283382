<template>
    <div class="row">
        <div class="col-md-5 grid-margin stretch-card">
            <div class="card">
                <div class="card-body">
                    <h6 class="card-title">Liberar Balances</h6>
                    <form @submit="createWinner">
                        <div class="form-group">
                            <select ref="game" class="form-control" v-model="place" @change="handlePlace($event)">
                                <option value="">Seleccionar Puesto</option>
                                <option v-for="(place, index) in places" :key="index" :value="place.id">{{ place.name }}</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <input type="text" class="form-control" v-model="amount" placeholder="Monto">
                        </div>
                        <div class="form-group">
                            <select class="form-control" v-model="type">
                                <option value="">Tipo</option>
                                <option value="paid">Pagar</option>
                                <option value="charge">Cobrar</option>
                            </select>
                        </div>
                        <div class="form-group mt-3">
                            <button type="submit" class="btn btn-app btn-block btn-primary">Aplicar Cambios</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="col-md-7" v-if="this.showDetail">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex justify-content-between align-items-baseline mb-2">
                        <h6 class="card-title mb-0">Historico De Balances</h6>
                        <strong v-if="currentPlace !== null" :class="[currentPlace.balance < 0 ? 'pl-1 text-danger' : 'pl-1 text-success']">₡ {{ formatPrice(currentPlace.balance) }}</strong>
                    </div>                    
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Puesto</th>
                                    <th>Monto</th>
                                    <th>Tipo</th>
                                    <th>Fecha</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(balance, index) in balances" :key="index">
                                    <td v-if="balance.place">{{ balance.place.name }}</td>
                                    <td>₡ {{ balance.amount|toCurrency }}</td>
                                    <td v-if="balance.type == 'charge'">Cobro</td>
                                    <td v-if="balance.type == 'paid'">Pago</td>
                                    <td>{{ balance.created_at|formatDate }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { required, minLength, between } from 'vuelidate/lib/validators'
    export default {
        props: [],
        data: function() {
            return {
                places: [],
                balances: [],
                currentPlace: null,
                place: '',
                amount: '',
                type: '',
                loading: false,
                showDetail: false
            }
        },
        filters: {
            toCurrency (value) {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
        },
        created() {
            //
        },
        async mounted() {
            await this.getPlaces();
        },
        computed: {
        },
        methods: {
            async getPlaces(id) {
                let res = await axios.get(`/admin/api/places`);
                this.places = res.data;
            },
            async getBalancesByPlace(id) {
                let res = await axios.get(`/admin/api/balances/${id}`);
                this.balances = res.data;
                this.showDetail = true;
            },
            handlePlace: async function(e){
                let placeId = e.target.value;
                let current = this.places.find(place => place.id == placeId);
                this.currentPlace = current;
                this.getBalancesByPlace(placeId);
            },
            handleBusted: async function(e){
                if(e.target.value == "yes"){
                    this.pay_busted = true;
                }else {
                    this.pay_busted = false;
                }
            },
            createWinner: async function(e){
                e.preventDefault();

                try {
                    let data = {
                        placeId: this.place,
                        amount: parseInt(this.amount),
                        type: this.type
                    }

                    let request = await axios.post(`/admin/api/applyBalance`, data);

                    this.getBalancesByPlace(this.place);
                    
                    this.place = '';
                    this.amount = '';
                    this.type = '';

                } catch (error) {
                    
                }
            },
            formatPrice(value) {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },
        }
    }
</script>
