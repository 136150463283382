<template>
    <div id="printMe">
        <div class="print_logo">
            <img src="https://ganatiempos.com/img/trebol_color.png" alt="GanaTiempos">
            <span>GanaTiempos</span>
        </div>
        <div class="print_detail">
            <strong>{{ place.name }}</strong>
        </div>
        <div class="print_buy">
            <span>{{ timestamp }}</span>
            <span>{{ currentGame.name }}</span>
            <span>{{ ticket.game_date|formatDate }} - {{ ticket.game_time|formatTime }}</span>
        </div>
        <div class="print_head">
            <strong>Nº</strong>
            <strong>MONTO</strong>
        </div>
        <div class="print_list">

            <div class="print_list_item" v-if="!currentGame.is_busted" v-for="(number, index) in groups" :key="index">
                <div>
                    {{ joinNumbers(number) }}
                </div>
                <span>{{ index }}</span>
            </div>

            <div class="print_list_item_reventado" v-if="currentGame.is_busted" v-for="(number, index) in sellings" :key="index">
                <div>{{ index }}</div>
                <div>
                    <span class="number_reventado">{{ joinAmount(number)|toCurrency }} - {{ sumBusted(number)|toCurrency }}</span>
                </div>
            </div>

        </div>
        <div class="print_head">
            <strong>TOTAL</strong>
            <strong class="print_head_total">{{ ticket.total|toCurrency }}</strong>
        </div>
        <div class="print_thanks">
            <span>¡Gracias por jugar!</span>
            <span>GanaTiempos</span>
        </div>
        <div class="print_number" v-if="ticket">
            <span>Ticket: #{{ ticket.number }}</span>
        </div>
        <div class="copy" v-if="is_copy == 'yes'">
            <strong>Copia - Copia - Copia - Copia</strong>
        </div>
    </div>
</template>
<script>
    import { mapGetters } from "vuex";
    import { mapMutations } from "vuex";
    import { validationMixin } from "vuelidate"
    import { required, minLength, between } from 'vuelidate/lib/validators'
    import moment from 'moment';
    import _ from 'lodash';
    export default {
        mixins: [validationMixin],
        props: ["data", "game", "logo", "place", "is_copy"],
        data: function() {
            return {
                ticket: this.data,
                currentGame: this.game
            }
        },
        filters: {
            toCurrency (value) {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
        },
        created() {
            //
        },
        mounted() {
            console.log(this.currentGame)
        },
        watch: {
           
        },
        computed: {
            keymap() {
                return {
                    "*": this.confirm,
                };
            },
            timestamp: function () {
                return moment().format('DD/MM/YYYY hh:mm a')
            },
            sellings() {
                return _.groupBy(this.ticket.list, 'number') 
            },
            groups() {
                return _.groupBy(this.ticket.list, 'amount') 
            },
        },
        methods: {
            joinNumbers(numbers){
                let news = [];
                numbers.map((item) => {
                    news.push(item.number)
                });
                return news.join('-');
            },
            joinBusted(numbers){
                let news = [];
                numbers.map((item) => {
                    news.push(`${item.amount}-${item.amount_busted}`)
                });
                return news.join('-');
            },
            joinAmount(numbers){
                let total = numbers.reduce((sum, sell) => sum + parseInt(sell.amount), 0);
                return total;
            },
            sumBusted(numbers){
                let total = numbers.reduce((sum, sell) => sum + parseInt(sell.amount_busted), 0);
                return total;
            },
            confirm(){
                let result = confirm("El ticket a imprimir es una copia!");
                if(result){
                    this.print();
                }
            },
            print: function(){
                window.print();
            }
        }
    }
</script>
